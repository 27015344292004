<template>
  <div class="qrcode-create-page">
    <v-qrcode-management />
  </div>
</template>

<script>
import VQRCodeManagement from "../../components/qrcodes/VQRCodeManagement";

import { QRCODE_TYPES } from "../../config";

export default {
  name: "QRCodeCreate",

  components: {
    "v-qrcode-management": VQRCodeManagement,
  },

  created() {
    const { codeType } = this.$route.query;

    const types = QRCODE_TYPES.map((type) => type.key);
    if (!types.includes(codeType)) {
      this.$router.push({ name: "qrcodes.types" });
    }
  },
};
</script>